import React, { useState, useEffect } from 'react';
import { ThreeDotsVertical, CaretDownFill, CaretRightFill, CheckCircle, XCircle } from 'react-bootstrap-icons';
import { getCurrentToken } from "../../services/cognito/cognitoAuth"
// "../services/cognito/cognitoAuth";
import axios from 'axios';

// import './CustomJsonViewer.css'; // For styles

const CustomJsonViewer = ({ jsonData, fileId }) => {
  const [jsonReviewData, setJsonReviewData] = useState(null);
  const [expandedPaths, setExpandedPaths] = useState({});
  const [selectedPath, setSelectedPath] = useState(null); // State for selected path
  const [reviewState, setReviewState] = useState({});
  const [tickCount, setTickCount] = useState(0);
  const [crossCount, setCrossCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [submissionStatus, setSubmissionStatus] = useState('');

  // Function to convert a date and time string to a Date object
  const parseDateTime = (dateTimeString) => {
    const [datePart, timePart] = dateTimeString.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  // Function to compare two date and time strings
  const compareDateTimes = (dateTimeString1, dateTimeString2) => {
    const date1 = parseDateTime(dateTimeString1);
    const date2 = parseDateTime(dateTimeString2);
    return date1.getTime() - date2.getTime();
  };

  // Function to get the higher of two date and time strings
  const getHigherDateTime = (dateTimeString1, dateTimeString2) => {
    const comparisonResult = compareDateTimes(dateTimeString1, dateTimeString2);
    return comparisonResult >= 0 ? dateTimeString1 : dateTimeString2;
  };

  // Function to get all paths in the JSON data
  useEffect(() => {
    const getAllPaths = (data = jsonData, path = 'root') => {
      let paths = { [path]: true };
      Object.keys(data).forEach(key => {
        const currentPath = `${path}.${key}`;
        if (typeof data[key] === 'object' && data[key] !== null) {
          if (Object.keys(data[key]).length > 0) {
            paths[currentPath] = true;
            paths = { ...paths, ...getAllPaths(data[key], currentPath) };
          }
        } else {
          paths[currentPath] = true;
        }
      });
      return paths;
    };
    setExpandedPaths(getAllPaths(jsonData));
  }, [jsonData]);


  // Function to get the reviewState from localStorage
  useEffect(() => {
    let localStoredData = {};
    let localStoredAt = '';
    let combinedReviewState = {};
    const savedReviewState = localStorage.getItem('reviewStateDraft');
    if (savedReviewState) {
      const saveData = JSON.parse(savedReviewState);
      if (saveData[fileId]) {
        localStoredData = saveData[fileId]['draftedData'];
        localStoredAt = saveData[fileId]['draftedSavedAt'];
      }
    }
    if (jsonReviewData && jsonReviewData.reviewedJson === null) {
      setReviewState(localStoredData);
    }
    else if (jsonReviewData && jsonReviewData.reviewedJson) {
      console.log('reviewUpdatedAt:', jsonReviewData.reviewUpdatedAt, localStoredAt);

      const reviewedJsonObject = JSON.parse(jsonReviewData.reviewedJson);
      if (localStoredAt === '') {
        setReviewState(reviewedJsonObject);
      }
      else {
        setIsDisabled(false);
        const higherDateTime = getHigherDateTime(localStoredAt, jsonReviewData.reviewUpdatedAt);
        if (higherDateTime === localStoredAt) {
          combinedReviewState = { ...reviewedJsonObject, ...localStoredData };
        }
        else {
          combinedReviewState = { ...localStoredData, ...reviewedJsonObject };
        }
        setReviewState(combinedReviewState);
        console.log('Combined reviewState:', combinedReviewState);
      }
    }
  }, [jsonReviewData]);

  // Function to fetch data from the API
  const fetchData = async (id) => {
    try {
      const token = await getCurrentToken();
      const url = `${process.env.REACT_APP_BACKEND_URL}fileLog/${id}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
      );
      setJsonReviewData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Call the API when the component mounts
  useEffect(() => {
    const fetchAndLogData = async () => {
      await fetchData(fileId);
    };
    fetchAndLogData();
  }, [fileId]);

  // Function to toggle expansion of an object
  const handleToggleExpand = (path) => {
    setExpandedPaths((prevState) => {
      const updatedState = { ...prevState };
      // if (prevState[path]) {
      // If the node is being collapsed, remove all its child paths
      // Object.keys(updatedState).forEach((key) => {
      //   if (key.startsWith(`${path}.`)) {
      //     delete updatedState[key];
      //   }
      // });
      // }  
      // Toggle the current path
      updatedState[path] = !prevState[path];
      return updatedState;
    });
  };


  // Function to handle clicks on the three dots
  const handleThreeDotsClick = (path) => {
    setSelectedPath(selectedPath === path ? null : path);
  };

  // Function to mark review status
  /* const handleReview = (data,path, status) => {
     setReviewState((prevState) => ({ ...prevState, [path]: status }));
     setSelectedPath(null); // Close the box after review
     console.log(reviewState);
   };*/


  useEffect(() => {
    const filteredReviewState = Object.entries(reviewState).filter(([key, value]) => key !== 'root');
    const tickCount = filteredReviewState.filter(([key, value]) => value === 'tick').length;
    const crossCount = filteredReviewState.filter(([key, value]) => value === 'cross').length;
    setTickCount(tickCount);
    setCrossCount(crossCount);
  }, [reviewState, tickCount, crossCount]);

  // Function to submit the review count to the API
  const submitCount = async () => {
    try {

      const token = await getCurrentToken();
      const reviewStateString = JSON.stringify(reviewState);
      console.log('reviewStateString:', reviewStateString);
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/update-review-count`,
        {
          "fileId": fileId,
          "wrongCount": crossCount,
          "correctCount": tickCount,
          "reviewedJson": reviewStateString
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Submit successful:', response);
      // Clear localStorage after submitting
      // Remove the specific fileId's entry from localStorage
      const existingDrafts = JSON.parse(localStorage.getItem('reviewStateDraft')) || {};
      delete existingDrafts[fileId];
      localStorage.setItem('reviewStateDraft', JSON.stringify(existingDrafts));
      // Set submission status
      setSubmissionStatus('Submission successful!');
      setIsDisabled(true);

      // Hide the submission status message after 5 seconds
      setTimeout(() => {
        setSubmissionStatus('');
      }, 2000);
    } catch (error) {
      console.error('Error submitting count:', error);
    }
  };

  // Function to get the current date and time in the specified format
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };



  // Function to update the reviewState to localStorage
  const updateToLocalStorage = (reviewState) => {
    // Get the existing reviewStateDraft from localStorage
    const existingDrafts = JSON.parse(localStorage.getItem('reviewStateDraft')) || {};

    // // Update the dictionary with the new reviewState and current date and time
    existingDrafts[fileId] = {
      draftedSavedAt: getCurrentDateTime(),
      draftedData: reviewState,
    };

    // Save the updated dictionary back to localStorage
    const reviewStateString = JSON.stringify(existingDrafts);
    localStorage.setItem('reviewStateDraft', reviewStateString);
  };


  // const handleReview = (data, path, status) => {
  //   setReviewState((prevState) => ({ ...prevState, [path]: status }));
  //   setSelectedPath(null); // Close the box after review

  //   const applyReviewToNestedElements = (data,currentPath) => {
  //     if (typeof data === 'object' && data !== null) {
  //       Object.keys(data).forEach(key => {
  //         const newPath = `${currentPath}.${key}`;
  //         setReviewState((prevState) => ({ ...prevState, [newPath]: status }));        
  //         applyReviewToNestedElements(data[key], newPath);
  //       });
  //     }
  //   };

  //   const traverseToPath = (data, path) => {
  //     const keys = path.split('.');
  //     const lastKey = keys.pop();      
  //     let currentData = data;
  //     if (currentData && lastKey in currentData) {
  //       applyReviewToNestedElements(currentData[lastKey], `${path}`);
  //     } else {
  //       console.error(`Last key ${lastKey} not found in data`);
  //     }
  //   };
  //   traverseToPath(data, path);

  //   const updateParentReviewStatus = (path) => {
  //     const keys = path.split('.');
  //     let localReviewState = { ...reviewState, [path]: status }; // Snapshot of current state

  //     while (keys.length > 1) {
  //       keys.pop();
  //       const parentPath = keys.join('.');
  //       const childPaths = Object.keys(localReviewState).filter((key) =>
  //         key.startsWith(`${parentPath}.`)
  //       );

  //       const childStatuses = childPaths.map((childPath) => localReviewState[childPath]);

  //       const allTick = childStatuses.every((childStatus) => childStatus === 'tick');
  //       const allCross = childStatuses.every((childStatus) => childStatus === 'cross');

  //       const parentStatus = allTick ? 'tick' : allCross ? 'cross' : 'cross';

  //       localReviewState[parentPath] = parentStatus;
  //       setReviewState((prevState) => ({ ...prevState, [parentPath]: parentStatus }));
  //     }
  //   };

  //   updateParentReviewStatus(path);
  // };

  const handleReview = (data, path, status) => {
    setReviewState((prevState) => {
      const newState = { ...prevState, [path]: status };
      updateToLocalStorage(newState);
      return newState;
    });
    setSelectedPath(null); // Close the box after review

    const applyReviewToNestedElements = (data, currentPath) => {
      if (typeof data === 'object' && data !== null) {
        Object.keys(data).forEach(key => {
          const newPath = `${currentPath}.${key}`;
          setReviewState((prevState) => {
            const newState = { ...prevState, [newPath]: status };
            updateToLocalStorage(newState);
            return newState;
          });
          applyReviewToNestedElements(data[key], newPath);
        });
      }
    };

    const traverseToPath = (data, path) => {
      const keys = path.split('.');
      const lastKey = keys.pop();
      let currentData = data;
      if (currentData && lastKey in currentData) {
        applyReviewToNestedElements(currentData[lastKey], `${path}`);
      } else {
        console.error(`Last key ${lastKey} not found in data`);
      }
    };
    traverseToPath(data, path);

    const updateParentReviewStatus = (path) => {
      const keys = path.split('.');
      let localReviewState = { ...reviewState, [path]: status }; // Snapshot of current state

      while (keys.length > 1) {
        keys.pop();
        const parentPath = keys.join('.');
        const childPaths = Object.keys(localReviewState).filter((key) =>
          key.startsWith(`${parentPath}.`)
        );

        const childStatuses = childPaths.map((childPath) => localReviewState[childPath]);

        const allTick = childStatuses.every((childStatus) => childStatus === 'tick');
        const allCross = childStatuses.every((childStatus) => childStatus === 'cross');

        const parentStatus = allTick ? 'tick' : allCross ? 'cross' : 'cross';

        localReviewState[parentPath] = parentStatus;
        setReviewState((prevState) => {
          const newState = { ...prevState, [parentPath]: parentStatus };
          updateToLocalStorage(newState);
          return newState;
        });
      }
    };

    updateParentReviewStatus(path);
    setIsDisabled(false);
  };


  // Helper function to apply color based on value type
  const getValueClass = (value) => {
    if (typeof value === 'string') {
      return 'json-string';
    } else if (typeof value === 'boolean') {
      return 'json-boolean';
    } else if (typeof value === 'number') {
      return 'json-number';
    } else if (value === null) {
      return 'json-null';
    } else if (typeof value === 'object') {
      return 'json-object';
    }
    return '';
  };

  // Recursive function to render JSON
  const renderJson = (data, path = '', level = 0) => {
    return Object.keys(data).map((key) => {
      const currentPath = path ? `${path}.${key}` : key;
      const isObject = typeof data[key] === 'object' && data[key] !== null;
      const isArray = Array.isArray(data[key]);
      const isEmpty = isObject && Object.keys(data[key]).length === 0;

      return (
        <React.Fragment key={currentPath}>
          {/* Key and Value Row */}
          <div
            // className="json-row-container"
            className={`json-row-container ${reviewState[currentPath] === 'tick' ? 'selected-tick' : ''} ${reviewState[currentPath] === 'cross' ? 'selected-cross' : ''}`}
            style={{ paddingLeft: `${(level + 1) * 10}px` }}>
            <div key={currentPath} className="json-row">
              <div className="key-container">
                {!isEmpty && isObject && (
                  <span onClick={() => handleToggleExpand(currentPath)} className="caret-icon">
                    {expandedPaths[currentPath] ? <CaretDownFill /> : <CaretRightFill />}
                  </span>
                )}
                <span className="json-key">{key}:</span>

                {/* Show empty array or object inline */}
                {isEmpty &&
                  <span className={`json-value ${getValueClass(data[key])}`}>      {isArray ? '[]' : '{}'}
                  </span>}

                {/* Opening bracket for expanded objects/arrays */}
                {isObject && expandedPaths[currentPath] && (
                  <span>{isArray ? '[' : '{'}</span>
                )}
              </div>

              {/* Show value if not an object */}
              {!isObject && (
                <span className={`json-value ${getValueClass(data[key])}`}>
                  {JSON.stringify(data[key])}
                </span>
              )}

              {/* Show collapsed indicator for objects/arrays */}
              {isObject && !expandedPaths[currentPath] && !isEmpty && (
                <span className="json-value">{isArray ? '[...]' : '{...}'}</span>
              )}


              {/* Always show three dots at the end */}
              <span className="three-dots-container" >
                {/* {!isObject && !isArray && ( */}
                <>
                  {selectedPath === currentPath && (
                    <span className="review-box">
                      <i className="tick" onClick={() => handleReview(data, currentPath, 'tick')}>
                        <CheckCircle />
                      </i>
                      <i className="cross" onClick={() => handleReview(data, currentPath, 'cross')}>
                        <XCircle />
                      </i>
                    </span>
                  )}

                  {/* Show review status only if review-box is not displayed */}
                  {!(selectedPath === currentPath) && reviewState[currentPath] && (
                    <span className={`review-result ${reviewState[currentPath] === 'tick' ? 'review-tick' : 'review-cross'}`}>
                      {reviewState[currentPath] === 'tick' ? <CheckCircle /> : <XCircle />}
                    </span>
                  )}

                  <span className={`three-dots ${selectedPath === currentPath ? 'active' : ''}`} onClick={() => handleThreeDotsClick(currentPath)}>
                    <ThreeDotsVertical />
                  </span>
                </>
                {/* )} */}
              </span>
            </div>
          </div>

          {/* Render nested JSON with proper indentation */}
          {isObject && expandedPaths[currentPath] && (
            <>
              {renderJson(data[key], currentPath, level + 1)}
              <div
                className={`json-row-container ${reviewState[currentPath] === 'tick' ? 'selected-tick' : ''} ${reviewState[currentPath] === 'cross' ? 'selected-cross' : ''}`}
                // className="json-row-container" 
                style={{ paddingLeft: `${(level + 1) * 10}px` }}>
                <span className="json-row">
                  {isArray ? ']' : '}'}
                </span>
              </div>
            </>
          )}
        </React.Fragment>
      );
    });
  };


  return (
    <div className="json-viewer-container">
      <div className="icon-container">
        <span className="round-rect-box tick">
          <CheckCircle />
          <span className="count">{tickCount}</span>
        </span>
        <span className="round-rect-box cross">
          <XCircle />
          <span className="count">{crossCount}</span>
        </span>
        <button
          title={isDisabled ? 'Saved, No draft available' : 'update count'}
          disabled={isDisabled}
          className={`round-rect-box submit ${isDisabled ? 'disabled' : ''}`}
          onClick={() => submitCount()}>
          Submit
        </button>
        {submissionStatus && <div className="submission-status">{submissionStatus}</div>}
      </div>
      <span onClick={() => handleToggleExpand('root')} className="caret-icon">
        {expandedPaths['root'] ? <CaretDownFill /> : <CaretRightFill />}
      </span>

      <span>{expandedPaths['root'] ? '{' : ''}</span>
      {expandedPaths['root'] ? (
        renderJson(jsonData, 'root')
      ) : (
        <span>{' {...} '}</span> // Show {...} if root is collapsed
      )}
      <span>{expandedPaths['root'] ? '}' : ''}</span> {/* Only show closing brace if expanded */}
    </div>
  );
};

export default CustomJsonViewer;

