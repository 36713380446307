import React, { useState, useContext, useEffect } from "react";
import ProjectContext from "../ProjectContext";
import axios from "axios";
import { getCurrentToken } from "../services/cognito/cognitoAuth";
import Pagination from "../components/Pagination";
import { Modal } from "react-bootstrap";
import { EyeFill, Search, PencilSquare, Trash } from "react-bootstrap-icons";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Select from "react-select";

function PresetTag() {
  const [searchQuery, setSearchQuery] = useState("");
  const { roles, userId, projectId } = useContext(ProjectContext);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState(0);
  const [totalListCount, setTotalListCount] = useState(0);
  const [searchName, setSearchName] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [tagToDelete, setTagToDelete] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const fetchTags = async () => {
    setLoading(true);
    try {
      const token = await getCurrentToken();
      const statusString =
        selectedStatus && selectedStatus.value ? selectedStatus.value : "ACTIVE,PENDING_REVIEW,DISABLED";

      const params = {
        page: currentPage,
        size: pageSize,
        name: searchName,
        sortColumn: "id",
        sortOrder: "DESC",
        status: statusString,
        userId: userId,
        role: Array.isArray(roles) ? roles.join(",") : roles,
        projectId: projectId,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}preset-tag/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );
      const parsedIssues = response.data.content.map((issue) => {
        let issueJson = {};
        try {
          if (issue.issueJson) {
            issueJson = JSON.parse(issue.issueJson);
          }
        } catch (error) {
          console.error("Error parsing issueJson:", error);
        }
        return {
          ...issue,
          issueJson,
        };
      });
      setTags(parsedIssues);
      const pageInfo = response.data.pageInfo;
      let adjustedTotalPageSize = pageInfo.totalPageSize;
      if (pageInfo.totalElements > 5 && pageInfo.totalElements % 5 !== 0) {
        adjustedTotalPageSize += 1;
      }
      setTotalPageSize(adjustedTotalPageSize);
      const totalList = response.data.pageInfo.totalElements;
      setTotalListCount(totalList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching issues:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (projectId) {
      fetchTags();
    }
  }, [currentPage, pageSize, searchName, selectedStatus, projectId]);
  const handleEdit = (tag) => {
    navigate(`/edit-preset-tag/${tag.id}`, { state: { tag } });
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    let statusOptions;
  
    if (roles.includes("ProjectAdmin")) {
      statusOptions = [
        { value: "ACTIVE", label: "ACTIVE" },
        { value: "DISABLED", label: "DISABLED" },
        { value: "PENDING_REVIEW", label: "PENDING_REVIEW" },
      ];
    } else {
      statusOptions = [
        { value: "ACTIVE", label: "ACTIVE" },
        { value: "PENDING_REVIEW", label: "PENDING_REVIEW" },
      ];
    }
  
    setStatuses(statusOptions);
  }, [roles]);
  const TagStatuses = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "DISABLED", label: "DISABLED" },
    { value: "PENDING_REVIEW", label: "PENDING_REVIEW" },
  ];
  const getFilteredStatuses = (currentStatus) => {
    if (currentStatus === "PENDING_REVIEW") {
      return TagStatuses.filter((status) => status.value !== "PENDING_REVIEW");
    } else if (currentStatus === "ACTIVE") {
      return TagStatuses.filter((status) => status.value === "DISABLED");
    } else if (currentStatus === "DISABLED") {
      return TagStatuses.filter((status) => status.value === "ACTIVE");
    }
    return [];
  };
  const handleStatusChange = async (presetTagId, newStatus) => {
    try {
      const token = await getCurrentToken();
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}preset-tag/status`,
        {
          id: presetTagId,
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Optionally, update the local state to reflect the change
      setTags((prevTags) =>
        prevTags.map((tag) =>
          tag.id === presetTagId ? { ...tag, status: newStatus } : tag
        )
      );
      if (newStatus === "DELETED") {
        setTags((prevTags) => prevTags.filter((tag) => tag.id !== presetTagId));
      }

    } catch (error) {
      console.error("Error updating status:", error);
      setError("Error updating status. Please try again.");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };
  const isReviewer = roles.includes("Reviewer");
  return (
    <div>
      <div className="pt-3 pb-2 d-flex align-items-center justify-content-between">
        <h2 className="fw-bold mb-0">PresetTag</h2>
        <div className="d-flex align-items-center flex-1">
          <Select
            options={statuses}
            onChange={(selectedOptions) => {
              setSelectedStatus(selectedOptions ? selectedOptions : []);
              setCurrentPage(1);
            }}
            value={selectedStatus}
            placeholder="Select Status"
            className="me-2 w-100"
            isClearable  
          />
          <div className="input-group flex-nowrap me-2">
            <span
              className="input-group-text bg-transparent"
              id="addon-wrapping"
            >
              <Search />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search by Name"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
                setCurrentPage(1);
              }}
            />
          </div>
          <Link
            to="/add-preset-tag"
            className="btn btn-primary px-3"
            style={{ minWidth: "150px" }}
          >
            Add PresetTag
          </Link>
        </div>
      </div>
      <div className="table-responsive border rounded p-2 mt-3">
        {loading ? (
          <p>Loading...</p>
        ) : tags.length > 0 ? (
          <table className="table table-borderless table-striped">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Tags</th>
                <th>Description</th>
                <th style={{ width: 200 }}>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tags.map((tag) => (
                <tr key={tag.id}>
                  <td>{tag.id}</td>
                  <td>{tag.name}</td>
                  <td>
                    {tag.issueJson && (
                      <ul className="ps-3 m-0">
                        {tag.issueJson.section && (
                          <li>
                            Section: <strong>{tag.issueJson.section}</strong>
                          </li>
                        )}
                        {tag.issueJson.subsection && (
                          <li>
                            Subsection:{" "}
                            <strong>{tag.issueJson.subsection}</strong>
                          </li>
                        )}
                        {tag.issueJson.issueNature && (
                          <li>
                            Issue Nature:{" "}
                            <strong>{tag.issueJson.issueNature}</strong>
                          </li>
                        )}
                        {tag.issueJson.issueType && (
                          <li>
                            Issue Type:{" "}
                            <strong>{tag.issueJson.issueType}</strong>
                          </li>
                        )}
                        {tag.issueJson.templateChangeType && (
                          <li>
                            Template Change Type:{" "}
                            <strong>{tag.issueJson.templateChangeType}</strong>
                          </li>
                        )}
                        {/* {tag.issueJson.tag && (
                          <li>
                            Tag: <strong>{tag.issueJson.tag}</strong>
                          </li>
                        )} */}
                      </ul>
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => setSelectedIssue(tag)}
                      className="btn btn-sm px-2 py-1 btn-outline-primary"
                      disabled={!tag.description && !tag.descriptionTitle}
                    >
                      <EyeFill size={20} />
                    </button>
                  </td>
                  <td>
                    {roles.includes("Reviewer") ? (
                      <>{tag.status}</>
                    ) : (
                      <Select
                        options={getFilteredStatuses(tag.status)}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            handleStatusChange(tag.id, selectedOption.value);
                          }
                        }}
                        value={TagStatuses.find(
                          (status) => status.value === tag.status
                        )}
                        placeholder="Select Status"
                      />
                    )}
                  </td>
                  <td>
                    <button
                      className="btn btn-link"
                      onClick={() => handleEdit(tag)}
                      disabled={isReviewer && tag.status === "ACTIVE"}
                    >
                      <PencilSquare size={18} />
                    </button>
                    {roles.includes("ProjectAdmin") && (
                      <button
                        className="btn btn-link text-danger"
                        onClick={() => {
                          setTagToDelete(tag.id);
                          setShowModal(true);
                        }}
                      >
                        <Trash size={18} />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No tags found</p>
        )}
        <Pagination
          currentPage={currentPage}
          totalPageSize={totalPageSize}
          onPageChange={handlePageChange}
          totalListCount={totalListCount}
          pageSize={pageSize}
        />
        {selectedIssue && (
          <Modal
            show={selectedIssue}
            size="lg"
            onHide={() => setSelectedIssue(null)}
          >
            <Modal.Header closeButton>
              <Modal.Title>{selectedIssue.descriptionTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="issue-desc">
              <div
                dangerouslySetInnerHTML={{ __html: selectedIssue.description }}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={() => setSelectedIssue(null)}
                className="btn btn-sm btn-secondary"
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        )}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Preset Tag</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this Preset Tag?
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                handleStatusChange(tagToDelete, "DELETED");
                setShowModal(false);
              }}
            >
              Delete
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default PresetTag;
